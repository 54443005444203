import React from 'react';
import { useDispatch } from 'redux-react-hook';
import styled from 'styled-components';
import { Row, Col, Typography, Select } from 'antd';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Item from './Item';
import { selectType } from '../store/actions';
import './ItemList.css';


const Title = Typography.Title;
const Option = Select.Option;

const Header = styled(Row)`
  margin: 20px 70px !important;
`;

const titles = {
  before: "Avant l'évènement",
  during: "Pendant l'évènement",
  after: "Après l'évènement"
};

export default ({ items, phase }) => {
  const dispatch = useDispatch();
  const title = titles[phase];
  return (
    <div>
      <Header>
        <Col span={16}>
          <Title level={3}>{title.toUpperCase()}</Title>
        </Col>
        <Col span={8}>
          <Select
            defaultValue=""
            allowClear={true}
            style={{ width: '100%' }}
            placeholder="Trier-par"
            onChange={selected => dispatch(selectType(phase, selected))}
          >
            <Option key="feature">Fonctionnalité</Option>
            <Option key="service">Service</Option>
          </Select>
        </Col>
      </Header>
      <TransitionGroup className="item-list">
        {items.map(item => {
          return (
            <CSSTransition key={item.sellsyRef} timeout={500} classNames="item">
              <Item item={item} />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
};

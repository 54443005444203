import Items from './Items';
import { isArray } from 'lodash/fp';

export const findAll = () => {
  const data = Items.map(item => {
    return {
      ...item,
      // This part of the code is fixing some difs between cockpit
      // and the required json
      // It's just for Valentin's comfort during first writing part
      // Then, as planned, he will edit the json
      variationRefs: isArray(item.variationRefs) ? item.variationRefs : [item.variationRefs],
      warnings: isArray(item.warnings) ? item.warnings : [item.warnings],
      info: isArray(item.infos) ? item.infos : [item.infos]
    };
  });
  return Promise.resolve(data);
};

export const getColorForCategory = category => {
  const color = {
    teasing: '#a4c36a',
    promotion: '#8d2bad',
    technicien: '#357dce',
    'mise en service': '#eb973f',
    statistiques: '#ac3860',
    'post-production': '#65b6af'
  }[category.toLowerCase()];
  return color || '#000';
};

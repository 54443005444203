import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Typography, Icon, Button } from 'antd';
import { getColorForCategory } from '../services/ItemsService';
import ItemModal from './ItemModal';

const Title = Typography.Title;

const ItemContainer = styled.div`
  margin: 20px 50px;
  background-color: #fff;
`;

const Category = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-transform: uppercase;
  color: #fff;
  font-weight: 100;
  white-space: nowrap;
  padding: 5px 0 5px 0;
`;

const CenteredItemsCol = styled(Col)`
  text-align: center;
`;

const Description = styled.div`
  white-space: pre-wrap;
`;

export default ({ item }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <ItemContainer>
      <Row
        style={{
          border: '1px solid #dae1e7',
          borderRadius: 3,
          margin: '30px 20px',
          display: 'flex'
        }}
      >
        <Col span={1} style={{ display: 'flex' }}>
          <Category style={{ backgroundColor: getColorForCategory(item.category) }}>
            {item.category}
          </Category>
        </Col>
        <CenteredItemsCol span={4}>
          <Icon type={item.icon} style={{ fontSize: 38, marginTop: 60 }} />
        </CenteredItemsCol>
        <Col span={19}>
          <Icon
            type={item.type === 'feature' ? 'build' : 'alert'}
            theme='filled'
            style={{
              float: 'right',
              margin: 5,
              fontSize: 16,
              color: '#606F7B'
            }}
          />
          <div style={{ margin: '20px 15px' }}>
            <Title level={4}>{item.title}</Title>
            <Description>
              {item.description.substring(0, 101)}
              {item.description.length > 101 && ' ...'}
            </Description>
            <div style={{ marginTop: 10, color: '#8795a1', fontSize: 13 }}>
              <span>Code Sellsy</span>{' '}
              <span style={{ fontWeight: 500, color: '#8795a1' }}>{item.sellsyRef}</span>
            </div>
            <Button style={{ marginTop: 10 }} onClick={() => setModalIsOpen(true)}>
              En savoir +
            </Button>
          </div>
        </Col>
      </Row>

      <ItemModal item={item} isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)} />
    </ItemContainer>
  );
};

import React from 'react';
import { Modal, Row, Col, Icon, Tag, Alert } from 'antd';
import styled from 'styled-components';
import { getColorForCategory } from '../services/ItemsService';

const Description = styled.div`
  white-space: pre-wrap;
`;

const renderAlertContent = (title, text) => {
  return (
    <div>
      <span style={{ fontWeight: 700 }}>{title} : </span> {text}
    </div>
  );
};

export default ({ item, isOpen, onClose }) => {
  return (
    <Modal
      width={800}
      visible={isOpen}
      bodyStyle={{
        padding: 0,
        fontSize: 16,
        lineHeight: 1.7
      }}
      onCancel={onClose}
      footer={null}
    >
      <img
        src={item.picture}
        style={{ width: '100%', borderBottom: '2px solid #63abf8' }}
        alt='Screenshot'
      />
      <div style={{ padding: '30px 30px 10px 30px' }}>
        <Row style={{ marginBottom: 25 }}>
          <Col span={3}>
            <Icon
              type={item.icon}
              style={{ fontSize: 50, margin: '10px 0 0 15px', color: '#000' }}
            />
          </Col>
          <Col>
            <div>
              <span
                style={{
                  fontSize: 24,
                  fontWeight: 500,
                  color: '#000',
                  marginRight: 15
                }}
              >
                {item.title}
              </span>
              <Tag
                color={getColorForCategory(item.category)}
                style={{ verticalAlign: 'text-bottom' }}
              >
                {item.category}
              </Tag>
              <Icon
                type={item.type === 'feature' ? 'build' : 'alert'}
                theme='filled'
                style={{
                  fontSize: 16,
                  color: '#606F7B',
                  verticalAlign: 'baseline'
                }}
              />
            </div>
            <div style={{ lineHeight: '18px' }}>
              Référence Sellsy{' '}
              <span style={{ fontWeight: 700, fontSize: 20 }}>{item.sellsyRef}</span>
            </div>
          </Col>
        </Row>
        <Description>{item.description}</Description>
        <div style={{ marginTop: 15 }}>
          {item.variationRefs.length > 0 ? 'Déclinaisons' : '(Aucune déclinaison)'}
        </div>
        <ul>
          {item.variationRefs.map((variationRef, i) => {
            return <li key={i}>{variationRef}</li>;
          })}
        </ul>
        <div style={{ marginTop: 30 }}>
          {item.warnings.map((warning, i) => {
            return (
              <Alert
                style={{ marginBottom: 10 }}
                key={i}
                message={renderAlertContent('Attention', warning)}
                type='warning'
                showIcon
              />
            );
          })}
        </div>
        <div>
          {item.info.map((info, i) => {
            return (
              <Alert
                style={{ marginBottom: 10 }}
                key={i}
                message={renderAlertContent('Commentaire', info)}
                type='info'
                showIcon
              />
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

import React, { useEffect } from 'react';
import { Layout, Row, Col } from 'antd';
import styled from 'styled-components';
import ItemList from '../components/ItemList';
import { useDispatch, useMappedState } from 'redux-react-hook';
import Header from '../components/Header';
import { findAllItems, selectSolution } from '../store/actions';
import {
  phaseBeforeItemsSelector,
  phaseDuringItemsSelector,
  phaseAfterItemsSelector,
  getAvailableSolutionsSelector
} from '../store/selectors';

const { Content } = Layout;

const Column = styled(Col)`
  min-height: calc(100vh - 64px - 80px) !important;
  margin: 40px 0;
`;

const BorderedColumn = styled(Column)`
  border-left: 1px solid #b8c2cc;
  border-right: 1px solid #b8c2cc;
`;

export default () => {
  const dispatch = useDispatch();
  const { beforeItems, duringItems, afterItems, solutions } = useMappedState(state => ({
    beforeItems: phaseBeforeItemsSelector(state),
    duringItems: phaseDuringItemsSelector(state),
    afterItems: phaseAfterItemsSelector(state),
    solutions: getAvailableSolutionsSelector(state)
  }));

  useEffect(() => {
    dispatch(findAllItems());
  }, []); //eslint-disable-line

  return (
    <Layout>
      <Header
        solutions={solutions}
        onSelectedSolution={solution => dispatch(selectSolution(solution))}
      />
      <Content style={{ backgroundColor: '#fff' }}>
        <Row>
          <Column span={8}>
            <ItemList items={beforeItems} phase='before' />
          </Column>
          <BorderedColumn span={8}>
            <ItemList items={duringItems} phase='during' />
          </BorderedColumn>
          <Column span={8}>
            <ItemList items={afterItems} phase='after' />
          </Column>
        </Row>
      </Content>
    </Layout>
  );
};

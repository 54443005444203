import { createSelector } from 'reselect';
import { flow, map, flatten, uniq, includes, isEmpty, trim } from 'lodash/fp';

const ALL_SOLUTIONS_KEY = 'Toutes les solutions';

const itemsSelector = state => state.items;
const selectedSolutionSelector = state => state.filters.selectedSolution;
const selectedTypeSelector = state => state.filters.selectedTypeForPhase;

// we get all the available solutions by getting each item solutions
export const getAvailableSolutionsSelector = createSelector(
  itemsSelector,
  items => {
    return [
      ALL_SOLUTIONS_KEY,
      ...flow(
        map('solutions'),
        flatten,
        map(trim),
        uniq
      )(items)
    ];
  }
);

const selectedTypeForPhaseSelector = phase => {
  return createSelector(
    selectedTypeSelector,
    items => {
      return items[phase.toLowerCase()];
    }
  );
};

// we return the items that are available for the specified phase (before, ...)
// AND the selected solution (tchat texte, ...)
const createPhaseItemsSelector = phase => {
  return createSelector(
    itemsSelector,
    selectedSolutionSelector,
    selectedTypeForPhaseSelector(phase),
    (items, selectedSolution, selectedType) => {
      return items.filter(item => {
        const itemIsCorrectPhase = item.phase.toUpperCase() === phase;
        const itemHasSolution = includes(selectedSolution, item.solutions);
        const itemHasCorrectType = isEmpty(selectedType) || item.type === selectedType;
        // don't filter by solution if none is selected or we need to show them all,
        // only filter by phase in this case
        if (isEmpty(selectedSolution) || selectedSolution === ALL_SOLUTIONS_KEY) {
          return itemIsCorrectPhase && itemHasCorrectType;
        }
        return itemIsCorrectPhase && itemHasSolution && itemHasCorrectType;
      });
    }
  );
};

export const phaseBeforeItemsSelector = createPhaseItemsSelector('BEFORE');
export const phaseDuringItemsSelector = createPhaseItemsSelector('DURING');
export const phaseAfterItemsSelector = createPhaseItemsSelector('AFTER');

import React from 'react';
import { Layout, Select } from 'antd';
import styled from 'styled-components';

const { Header } = Layout;
const Option = Select.Option;

const TopBar = styled(Header)`
  background-color: #51abff !important;
  color: white;
`;

export default ({ solutions, onSelectedSolution }) => {
  return (
    <TopBar>
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder='Choisir une solution'
        onChange={solution => onSelectedSolution(solution)}
      >
        {solutions.map(solution => {
          return (
            <Option key={solution} value={solution}>
              {solution}
            </Option>
          );
        })}
      </Select>
    </TopBar>
  );
};

// {
//   phase: '',             before ou during | after
//   solutions: [''],       tchatTexte, etc.
//   type: '',              feature ou service
//   icon: '',              à choisir parmis ceux-là : https://ant.design/components/icon/
//   title: '',             titre
//   description: '',       description
//   category: '',          rubrique d'appartenance
//   sellsyRef: '',         référence sellsy
//   variationRefs: [''],   déclinaisons de l'article (séparer par des virgules)
//   picture: '',           lien de l'image
//   warnings: [''],        texte Warning (on peut en mettre plusieurs séparés par des virgules)
//   info: ['']             texte Information (on peut en mettre plusieurs séparés par des virgules)
// }
export default [
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: 'Frais de mise en service et int\u00e9gration',
    description:
      "- Cr\u00e9ation de votre espace et compte client sur la plateforme Livee \\n\\n\n\n- Int\u00e9gration de l'\u00e9v\u00e9nement \u00e0 votre support web (iframe) ou via une page d\u00e9di\u00e9e\\n\n- Choix d'un template pr\u00e9configur\u00e9 adapt\u00e9 \u00e0 votre code couleur\\n\n\n- Int\u00e9gration de votre banni\u00e8re et habillage\\n\n\n- Personnalisation d'un jeu de pages comprenant les phases de teasing, live, cl\u00f4ture)\n\n- Tests, validation et mise en ligne\n- Acc\u00e8s 24/24, 7/7 au back-office / compte-client\n- Le retrait du logo Livee ou mention Powered by Livee\n\nCeci n'inclut pas :\n\nla modification de l'emplacement des modules, \nla modification de tous les textes ou libell\u00e9s faisant partie des modules, \nla modification de la police et taille de police des textes",
    category: 'Mise en service web',
    sellsyRef: 'MES.STD',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556612673,
    _created: 1556612673,
    _id: '5cc80641356464001400017f'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: 'Mise \u00e0 jour de la page de votre compte (\u00e0 partir du 2e tchat)',
    description:
      '- Mise \u00e0 jour de votre compte et du jeu de pages existant sur la base du mod\u00e8le initial (comprenant phase de teasing, live, cl\u00f4ture)\n- Adaptation graphique : code couleur, habillage\n- Modification \u00e9ditoriale : \u00e9l\u00e9ments textuels, edito\n- Tests, validation et mise en ligne\n- Acc\u00e8s 24/24, 7/7 au back-office / compte-client',
    category: 'Mise en service web',
    sellsyRef: 'MAJ.MES',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556613172,
    _created: 1556613172,
    _id: '5cc80834356464001e000324'
  },
  {
    phase: 'before',
    solutions: [' Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: 'Compatibilit\u00e9 sp\u00e9cifique',
    description:
      "- Optimisation de votre \u00e9v\u00e9nement pour des p\u00e9riph\u00e9riques et/ou navigateurs sp\u00e9cifiques\n- Personnalisation d'une page sp\u00e9cifiquement adapt\u00e9e au support",
    category: 'Mise en service web',
    sellsyRef: 'OPT.MOB',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556613333,
    _created: 1556613333,
    _id: '5cc808d5356464001e0000a8'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: "Emailing d'invitation ",
    description:
      "- Envoi d'un email d'invitation \u00e0 partir d'une BDD fournie par vos soins\n- Personnalisation du message et adaptation \u00e0 vos couleurs\n- Parametrage, phase test et mise en service \n- Dans la limite de 2500 adresses emails",
    category: 'Communication / Promotion du tchat',
    sellsyRef: 'OPT.EMAILING',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556613621,
    _created: 1556613621,
    _id: '5cc809f5356464001e000038'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: 'Nom de domaine personnalis\u00e9',
    description:
      "- Sur la base d'une nom de domaine fourni par vos soins\n- Param\u00e9trage de l'URL de votre page web\n- H\u00e9bergement du web \u00e9v\u00e9nement sous un nom de domaine personnalis\u00e9\n- Configuration technique, test et mise en ligne",
    category: 'Mise en service web',
    sellsyRef: 'OPT.DOMAINE',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556613750,
    _created: 1556613750,
    _id: '5cc80a7635646400210000ab'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: 'Alerte SMS - Pack de SMS',
    description:
      "- Envoi d'un SMS pour annoncer le d\u00e9but du tchat \u00e0 partir d\u2019une BDD fournie par vos soins ou bien suite \u00e0 l'inscription de l'utilisateur aux alertes en page de teasing\n- Personnalisation du message\n- Parametrage, phase test et mise en service \n- Export des num\u00e9ros disponible a posteriori",
    category: 'Communication / Promotion du tchat',
    sellsyRef: 'ALERTESMS',
    variationRefs: [
      'ALERTESMS.100',
      'ALERTESMS.200',
      'ALERTESMS.300',
      'ALERTESMS.400',
      'ALERTESMS.500',
      'ALERTESMS.1000'
    ],
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556613961,
    _created: 1556613961,
    _id: '5cc80b4935646400250002cc'
  },
  {
    phase: 'before',
    solutions: [' Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: "S\u00e9curisation de l'acc\u00e8s \u00e0 votre \u00e9v\u00e9nement",
    description:
      "- Param\u00e9trage technique pour restreindre l'acc\u00e8s plages IP (fournies par vos \u00e9quipes IT) ou par lien r\u00e9f\u00e9rent.\n- Param\u00e9trage, int\u00e9gration et phase de test",
    category: "Acc\u00e8s \u00e0 l'espace de tchat",
    sellsyRef: 'OPT.SECURE.ACCES',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556614116,
    _created: 1556614116,
    _id: '5cc80be4356464001a0003e0'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: 'Interfa\u00e7age annuaire utilisateurs / int\u00e9gration SSO',
    description:
      "- Fourniture d'un exemple de script en java ou php\n- SSO avec votre base d' utilisateurs / votre annuaire ou liste d'inscription\n- Transmission de donn\u00e9es sp\u00e9cifiques : nom, pr\u00e9nom, email\n- Param\u00e9trage, phase test et mise en service",
    category: "Acc\u00e8s \u00e0 l'espace de tchat",
    sellsyRef: 'OPT.ANNUAIRE',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556614197,
    _created: 1556614197,
    _id: '5cc80c35356464001a000214'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title:
      'Invitation + Acc\u00e8s restreint \u00e0 votre \u00e9v\u00e9nement via identifiant/mot de passe',
    description:
      "- Int\u00e9gration d'un module d'identification pour acc\u00e9der \u00e0 votre page\n- Envoi d'un email d'invitation comprenant les codes d'acc\u00e8s \u00e0 la page\n- Selon une BDD email fournie par vos soins\n- Personnalisation du message et adaptation \u00e0 vos couleurs\n- Acc\u00e8s via un login/mot de passe personnalis\u00e9 ou commun\n- Param\u00e9trage, phase test et mise en service\n- Export d'un tracking utilisateur a posteriori",
    category: "Acc\u00e8s \u00e0 l'espace de tchat",
    sellsyRef: 'OPT.INVIT.INSCRIPTION',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556636024,
    _created: 1556635525,
    _id: '5cc85f85356464001a000209'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: 'Tchat texte \u00e9v\u00e9nementiel texte interne',
    description:
      "- Int\u00e9gration d'un module d'identification pour acc\u00e9der \u00e0 votre page\n- Acc\u00e8s via un login/mot de passe commun*\n- Param\u00e9trage, phase test et mise en service\n\n* Ceci n'inclut pas d'emailing informant les utilisateurs; il est pris en charge par vos soins \u00e9ventuellement ou bien les informations sont communiqu\u00e9es par d'autres canaux.",
    category: "Acc\u00e8s \u00e0 l'espace de tchat",
    sellsyRef: 'OPT.INVIT.GENERIQUE',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556636240,
    _created: 1556636240,
    _id: '5cc86250356464001a000108'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: 'Tchat texte \u00e9v\u00e9nementiel texte interne',
    description:
      "- Mise en ligne d'une page de pr\u00e9-\u00e9v\u00e9nement \u00e0 vos couleurs\n- Edito/Pr\u00e9sentation de l'\u00e9v\u00e9nement\n- Fonction : Poser une question ou poster un commentaire \u00e0 l'avance\n- Voter/Liker les questions d\u00e9j\u00e0 pos\u00e9es\n- Partage l'\u00e9v\u00e9nement sur les r\u00e9seaux sociaux",
    category: 'Teasing',
    sellsyRef: 'OPT.TEASING',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556636489,
    _created: 1556636489,
    _id: '5cc8634935646400200003c2'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: 'Alertez-moi',
    description:
      "- Int\u00e9gration d'un module d'alerte \u00e0 la page de teasing\n- Saisie d'une adresse email ou num\u00e9ro de mobile par l'internaute\n- Envoi d'un email pour annoncer le d\u00e9but du tchat aux adresses recueillies\n- Personnalisation du message et int\u00e9gration de votre logo\n- Choix de la date/heure d'envoi selon votre retroplanning\n- Param\u00e9trage, phase test et mise en service\n- Export des emails disponible a posteriori",
    category: 'Teasing',
    sellsyRef: 'OPT.ALERTE',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556636596,
    _created: 1556636596,
    _id: '5cc863b4356464001d00023d'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'service',
    icon: '',
    title: 'Accompagnement technique sp\u00e9cifique - Int\u00e9gration au r\u00e9seau interne',
    description:
      "- Audit technique et diagnostique concernant la compatibilit\u00e9 de votre r\u00e9seau d'entreprise et vos contraintes de s\u00e9curit\u00e9\n- Conseil technique pour un d\u00e9ploiement optimal sur la base du diagnostique \u00e9tabli",
    category: 'Accompagnement',
    sellsyRef: 'IT.ACC.TECH',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556805604,
    _created: 1556636931,
    _id: '5cc86503356464002c000295'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'service',
    icon: '',
    title: 'Accompagnement \u00e9ditorial',
    description:
      "- Accompagnement t\u00e9l\u00e9phonique sur le contenu et les \u00e9changes \u00e0 aborder lors de votre \u00e9v\u00e9nement\n- Brief th\u00e9matique par t\u00e9l\u00e9phone jusqu'\u00e0 2 semaines avant votre \u00e9v\u00e9nement\n- Travail de recherche \u00e9ditoriale\n- D\u00e9finition d'un script adapt\u00e9 et pr\u00e9paration d'un sc\u00e9nario\n- Point t\u00e9l\u00e9phonique de validation de la ligne \u00e9ditoriale et du filage de l'\u00e9v\u00e9nement\n- Brief en compagnie des diff\u00e9rents intervenants",
    category: 'Accompagnement',
    sellsyRef: 'EDIT.ACC.TEL',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556805616,
    _created: 1556637268,
    _id: '5cc86654356464002d0003be'
  },
  {
    phase: 'before',
    solutions: ['\tTchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'service',
    icon: '',
    title: 'Traduction question en avance',
    description:
      'Tarification selon la langue, volume \u00e0 traduire (nombre de mots limite), domaine de sp\u00e9cialit\u00e9 et mode de traduction : traduction \u00e9crite.',
    category: 'Accompagnement',
    sellsyRef: 'Free.item',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556805622,
    _created: 1556637396,
    _id: '5cc866d4356464002800008f'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: 'Tracking sp\u00e9cifique',
    description:
      "- Prise en compte du brief client et du nombre de source \u00e0 tracker ( r\u00e9seaux sociaux, newsletter, banni\u00e8res etc\u2026 ) (20 max.)\n- Cr\u00e9ation des urls personnalis\u00e9es en fonction des sources\n- Phase de test des URL et de bonne r\u00e9ception des statistiques dans Analytics\n- Phase de validation avec le client ( Avons-nous bien track\u00e9 toute les sources que vous demandiez ?)\n- Livraison des URLs\n- Rapport d'analyse formalis\u00e9",
    category: 'Accompagnement',
    sellsyRef: 'OPT.TRACKINGSPE',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556805575,
    _created: 1556637505,
    _id: '5cc86741356464001a0002db'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'service',
    icon: '',
    title: 'R\u00e9p\u00e9tition simple du tchat en amont',
    description:
      "- R\u00e9alisation d'une r\u00e9p\u00e9tition du dispositif live avant votre \u00e9v\u00e9nement\n- En compagnie de votre chef de projet\n- Mise en situation : installation du mat\u00e9riel et d\u00e9roulement par \u00e9tape\n\n* Frais de mission en sus pour une prestation hors Paris/RP",
    category: 'Formation / R\u00e9p\u00e9tition',
    sellsyRef: 'REPET.CHAT',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556637873,
    _created: 1556637873,
    _id: '5cc868b1356464002a00020c'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'service',
    icon: '',
    title: 'Conseil + formation \u00e0 la plateforme dans vos locaux',
    description:
      '- Conseil (organisation, logistique, gestion \u00e9ditoriale)\n- Gestion compl\u00e8te de vos \u00e9v\u00e9nements: programmation, teasing, live, vid\u00e9o, slide, mod\u00e9ration, export des donn\u00e9es, publication, mise \u00e0 jour\n- Prise en main g\u00e9n\u00e9rale de la plateforme**\n- Mise en application de process optimis\u00e9\n- Partage de best practices pour un live fluide et r\u00e9ussi\n\n- 1/2 journ\u00e9e de formation et r\u00e9p\u00e9tition dans vos locaux*\n- Documents tutos fournis\n- 3 personnes maximum par session\n\n*frais de mission en sus pour un \u00e9v\u00e9nement hors Paris et RP\n**mat\u00e9riel non inclus',
    category: 'Formation / R\u00e9p\u00e9tition',
    sellsyRef: 'FORM.AUTONOME.PRESENTIELLE',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556786328,
    _created: 1556786328,
    _id: '5ccaac98356464002e00022e'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'service',
    icon: '',
    title:
      'Conseil + formation compl\u00e9mentaire - Gestion autonome de vos \u00e9v\u00e9nements ',
    description:
      "- Formation compl\u00e9mentaire suite \u00e0 une formation initiale\n- Rappel de la cr\u00e9ation et programmation de session \n- Param\u00e9trage des alertes et mailing\n- Cr\u00e9ation ou modification des profils\n- Accompagnement sur les points de vigilance\n- Gestion vid\u00e9o non incluse\n\n- 1 session d'1h30 de formation t\u00e9l\u00e9phonique\n- Documents tutos fournis\n- 3 personnes maximum par session",
    category: 'Formation / R\u00e9p\u00e9tition',
    sellsyRef: 'FORM.SUPP',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556786461,
    _created: 1556786461,
    _id: '5ccaad1d356464001a000205'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: 'D\u00e9veloppement sp\u00e9cifique - jour / homme',
    description: 'D\u00e9veloppement / adaptation sp\u00e9cifique',
    category: 'D\u00e9veloppement sp\u00e9cifique',
    sellsyRef: 'IT.DEVELOPPEMENT.SPE',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556786557,
    _created: 1556786557,
    _id: '5ccaad7d356464002d00009c'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'service',
    icon: '',
    title: 'Direction de projet technique - j/h',
    description: '',
    category: 'Gestion de projet',
    sellsyRef: 'DIR.PROJET.TECH',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556803081,
    _created: 1556803081,
    _id: '5ccaee0935646400270001a1'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'service',
    icon: '',
    title: 'Gestion de projet d\u00e9di\u00e9e - Forfait journ\u00e9e',
    description:
      "- Accompagnement projet sp\u00e9cifique\n- Brief de lancement et r\u00e9troplanning\n- Coordination des diff\u00e9rents intervenants\n- Supervision technique et tests\n- Point \u00e0 mi-parcours\n- Suivi quotidien et r\u00e9unions t\u00e9l\u00e9phoniques de validation selon votre r\u00e9troplanning\n\n* Susceptible de varier selon l'\u00e9volution du cadre du projet",
    category: 'Gestion de projet',
    sellsyRef: 'GEST.PROJET',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556803470,
    _created: 1556803382,
    _id: '5ccaef363564640027000170'
  },
  {
    phase: 'during',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: 'Chat \u00e9v\u00e9nementiel texte - Intranet',
    description:
      "- H\u00e9bergement d'une session de tchat texte en live sur votre r\u00e9seau interne\n- Interfaces responsives au format web accessibles sans installation logicielle\n- Module technique pour travers\u00e9e de pare-feu \n- jusqu'\u00e0 XXX connexions maximum\n\nFonctionnalit\u00e9s live : \n- Module de questions/r\u00e9ponses mod\u00e9r\u00e9 vu par l'internaute\n- Module de tchat permettant \u00e0 l'internaute de poser des questions\n- Interface de mod\u00e9ration et pr\u00e9-mod\u00e9ration\n- Interface de saisie et publication des r\u00e9ponses au format texte\n\nA posteriori :\n- Rapport statistique d'audience\n- Contenus libres de droits exportable\n\nHotline du lundi au vendredi de 9h30 \u00e0 18h",
    category: 'H\u00e9bergement',
    sellsyRef: 'CHAT.TEXTE.INTRANET',
    variationRefs: [
      'CHAT.TEXTE.INTRANET.100',
      'CHAT.TEXTE.INTRANET.250',
      'CHAT.TEXTE.INTRANET.500',
      'CHAT.TEXTE.INTRANET.1000'
    ],
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556804056,
    _created: 1556803986,
    _id: '5ccaf192356464002d0001e3'
  },
  {
    phase: 'during',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: 'Fonctionnalit\u00e9 votes/quizz en live',
    description:
      "- Int\u00e9gration d'un module de sondages en temps r\u00e9el \u00e0 votre page de live\n- Interface de gestion et personnalisation des questionnaires\n- Publication de sondages et leurs r\u00e9sultats en temps r\u00e9el\n- Param\u00e9trage, int\u00e9gration, test, validation et mise en ligne\n- Export des r\u00e9sultats disponible a posteriori\n\n* Inclut la saisie de 5 questionnaires ou gestion autonome",
    category: 'Interactivit\u00e9 suppl\u00e9mentaire',
    sellsyRef: 'OPT.QUIZZ',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556804401,
    _created: 1556804401,
    _id: '5ccaf331356464001a0001cc'
  },
  {
    phase: 'before',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: 'Fonctionnalit\u00e9 Diaporama photo',
    description:
      "- Int\u00e9gration d'un module photo \u00e0 votre page de live\n- Diffusion simultan\u00e9e de photos pendant votre \u00e9v\u00e9nement\n- Interface de mise en ligne et gestion des visuels\n- Param\u00e9trage, int\u00e9gration, test, validation et mise en ligne",
    category: 'Interactivit\u00e9 suppl\u00e9mentaire',
    sellsyRef: 'OPT.DIAPO',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556804899,
    _created: 1556804899,
    _id: '5ccaf523356464002f000155'
  },
  {
    phase: 'during',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: 'Questionnaire de satisfaction - Sondage',
    description:
      "- Mise en ligne d'un questionnaire/sondage sur votre page\n- Publication pendant ou en fin d'\u00e9v\u00e9nement\n- Int\u00e9gration de questions ouvertes, ferm\u00e9es, QCM...\n- Interface de gestion et personnalisation du questionnaire\n- Export des r\u00e9sultats depuis votre compte client\n- Param\u00e9trage, int\u00e9gration, test, validation et mise en ligne",
    category: 'Interactivit\u00e9 suppl\u00e9mentaire',
    sellsyRef: 'OPT.QUESTIONNAIRE',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556804993,
    _created: 1556804993,
    _id: '5ccaf5813564640030000182'
  },
  {
    phase: 'during',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: 'Pack de connexions suppl\u00e9mentaires - Format texte',
    description:
      "Palier de connexions additionnels d\u00e9clench\u00e9 en cas de d\u00e9passement du forfait inital\n- Dans le cadre d'un \u00e9v\u00e9nement au format texte uniquement",
    category: 'Connexions suppl\u00e9mentaires',
    sellsyRef: 'PALLIERS.TEXTE',
    variationRefs: [
      'PALLIERS.TEXTE.100',
      'PALLIERS.TEXTE.250',
      'PALLIERS.TEXTE.500',
      'PALLIERS.TEXTE.1000'
    ],
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556805270,
    _created: 1556805235,
    _id: '5ccaf673356464003300036b'
  },
  {
    phase: 'during',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title:
      'Mise en \u0153uvre de ressources serveurs sp\u00e9cifiques pour 1000 connexions suppl\u00e9mentaires garanties',
    description:
      '- Mise \u00e0 disposition de ressources serveurs suppl\u00e9mentaires pour le traitement de vos messages tchat en temps r\u00e9el.\n\n- Optimisation logicielles pour la mont\u00e9e en charge\n\n- Cr\u00e9ation d\'une page d\u00e9di\u00e9e sp\u00e9cifique "light"*\n\n* Ceci exclut toute autre module d\'interaction temps r\u00e9el.',
    category: 'Connexions suppl\u00e9mentaires',
    sellsyRef: 'IT.TCHAT.INTERNE',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556805523,
    _created: 1556805523,
    _id: '5ccaf7933564640032000176'
  },
  {
    phase: 'during',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'service',
    icon: '',
    title: 'Mod\u00e9rateur sur site le Jour J - Forfait 1/2 journ\u00e9e',
    description:
      " - Mod\u00e9ration des questions par un membre de l'\u00e9quipe LiveE sur site\n- Gestion et publication des questions pendant le live \n- Mod\u00e9ration technique de mani\u00e8re autonome \n- Brief en amont pour d\u00e9finir la ligne \u00e9ditoriale et vos contraintes de publication\n- Assistance de votre \u00e9quipe pour la s\u00e9lection des questions\n\n* Frais de mission en sus pour une prestation hors Paris/RP",
    category: 'Accompagnement',
    sellsyRef: 'MOD.1/2JOURNEE',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556807339,
    _created: 1556807167,
    _id: '5ccafdff35646400360003ad'
  },
  {
    phase: 'during',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'service',
    icon: '',
    title: 'Script sur site le Jour J - Forfait 1/2 journ\u00e9e',
    description:
      "- Gestion des r\u00e9ponses par un membre de l'\u00e9quipe LiveE sur site\n- Il saisit les r\u00e9ponses dict\u00e9es par l'invit\u00e9 \n- Publication des messages en temps r\u00e9el\n- Brief en amont pour d\u00e9finir le contexte et le vocabulaire \u00e0 respecter",
    category: 'Accompagnement',
    sellsyRef: 'SCRIPT.1/2JOURNEE',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556807257,
    _created: 1556807257,
    _id: '5ccafe59356464002f00021d'
  },
  {
    phase: 'during',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'service',
    icon: '',
    title: 'Support technique LIVEE sur site le jour J (forfait 1/2 journ\u00e9e)',
    description:
      "- V\u00e9rification des pr\u00e9-requis techniques et informatiques sur le mat\u00e9riel install\u00e9\n- Tests des connexions sur site \n- Assistance fonctionnelle ou technique tout au long de l'\u00e9v\u00e9nement",
    category: 'Accompagnement',
    sellsyRef: 'SUPPORT.PROJET',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556807686,
    _created: 1556807512,
    _id: '5ccaff5835646400350001e4'
  },
  {
    phase: 'during',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'service',
    icon: '',
    title: 'Support technique sur site le Jour J - Forfait demi-journ\u00e9e',
    description:
      "- Intervention d'un ing\u00e9nieur support LiveE sur site le jour J avec mat\u00e9riel\n- Support technique tout au long de votre tchat\n\n* Frais de mission en sus pour une prestation hors Paris/RP",
    category: 'Accompagnement',
    sellsyRef: 'IT.SUPPORT.CHAT.1/2JOURNEE',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556807795,
    _created: 1556807795,
    _id: '5ccb00733564640035000088'
  },
  {
    phase: 'during',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'service',
    icon: '',
    title: "Photos des coulisses de l'\u00e9v\u00e9nement - Forfait 1/2 journ\u00e9e",
    description:
      "- R\u00e9alisation de photos des coulisses de votre \u00e9v\u00e9nement comme alternative \u00e0 la vid\u00e9o\n- Prise de vues en direct par un photographe LiveE sur site\n- Traitement et mise en ligne des photos sur la page de l'\u00e9v\u00e9nement en temps r\u00e9el\n- Les internautes visionnent les photos via un module d\u00e9di\u00e9 sur la page\n\n* Frais de mission en sus pour une prestation hors Paris/RP",
    category: 'Accompagnement',
    sellsyRef: 'PHOTO.1/2JOURNEE',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556807900,
    _created: 1556807900,
    _id: '5ccb00dc356464001a0001e2'
  },
  {
    phase: 'after',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: 'Questionnaire de satisfaction - Sondage',
    description:
      "- Mise en ligne d'un questionnaire/sondage sur votre page\n- Publication pendant ou en fin d'\u00e9v\u00e9nement\n- Int\u00e9gration de questions ouvertes, ferm\u00e9es, QCM...\n- Interface de gestion et personnalisation du questionnaire\n- Export des r\u00e9sultats depuis votre compte client\n- Param\u00e9trage, int\u00e9gration, test, validation et mise en ligne",
    category: 'Apr\u00e8s le tchat',
    sellsyRef: 'OPT.QUESTIONNAIRE',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556808189,
    _created: 1556808189,
    _id: '5ccb01fd3564640030000346'
  },
  {
    phase: 'after',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'feature',
    icon: '',
    title: 'Page de replay disponible apr\u00e8s l\u2019\u00e9v\u00e9nement - Tchat texte',
    description:
      "- Int\u00e9gration et habillage d'une page de replay de votre \u00e9v\u00e9nement\n- \u00c9dition du transcript en back office\n- Publication de l'\u00e9change questions/r\u00e9ponses\n- H\u00e9bergement inclus - forfait 12 mois\n- Export de la page possible au format html\n- Param\u00e9trage, int\u00e9gration, test, validation et mise en ligne",
    category: 'Apr\u00e8s le tchat',
    sellsyRef: 'REPLAY.CHAT.TEXTE',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556808270,
    _created: 1556808252,
    _id: '5ccb023c35646400270001b2'
  },
  {
    phase: 'after',
    solutions: ['Tchat texte \u00e9v\u00e9nementiel texte interne'],
    type: 'service',
    icon: '',
    title: 'Edition et correction du transcript',
    description:
      "- Modifications de l'\u00e9change questions/r\u00e9ponses \n- Organisation par th\u00e9matique\n- Corrections orthographiques\n- Livraison libre de droits et publication si h\u00e9bergement par nos soins",
    category: 'Apr\u00e8s le tchat',
    sellsyRef: 'EDIT.TRANSC',
    variationRefs: '',
    picture: '',
    warnings: '',
    infos: '',
    _mby: '5cc7fb51356464001000020f',
    _by: '5cc7fb51356464001000020f',
    _modified: 1556808318,
    _created: 1556808318,
    _id: '5ccb027e35646400270001df'
  }
];

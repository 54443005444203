import types from './types';
import { findAll } from '../services/ItemsService';

export const findAllItems = () => dispatch => {
  return findAll().then(items => {
    return dispatch({
      type: types.ITEMS_FIND_ALL,
      payload: items
    });
  });
};

export const selectSolution = solution => dispatch => {
  return dispatch({
    type: types.SELECTED_SOLUTION,
    payload: solution
  });
};

export const selectType = (phase, selected) => dispatch => {
  return dispatch({
    type: types.SELECTED_TYPE,
    payload: { selected, phase }
  });
};

import types from './types';

const initialState = {
  items: [],
  filters: {
    selectedSolution: '',
    selectedTypeForPhase: {
      before: '',
      during: '',
      after: ''
    }
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.ITEMS_FIND_ALL:
      return {
        ...state,
        items: payload
      };

    case types.SELECTED_SOLUTION:
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedSolution: payload
        }
      };
    case types.SELECTED_TYPE:
      const { phase, selected } = payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedTypeForPhase: {
            ...state.filters.selectedTypeForPhase,
            [phase]: selected
          }
        }
      };
    default:
      return state;
  }
};
